import axiosIns from "@/libs/axios";

const get = async ({ page, limit, searchQuery }) => {
  return await axiosIns.get(`usuarios`)
}

const getById = async (id) => {
  return await axiosIns.get(`usuarios/${id}`)
}

const store = async (data) => {
  return await axiosIns.post('usuarios', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`usuarios/${id}`, data)
}

const destroy = async (id) => {
  return await axiosIns.delete(`usuarios/${id}`)
}

const resetPassword = async (id, data) => {
  return await axiosIns.post(`usuarios/${id}/password-reset`, data)
}

const getUsuarioPorSede = async ({ sede_id, q }) => {
  return await axiosIns.get(`usuarios/por-sede?sede_id=${sede_id}&q=${q}`)
}

export default {
  get,
  getById,
  store,
  update,
  destroy,
  resetPassword,
  getUsuarioPorSede,
}
