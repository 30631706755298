<template>
  <div>
    <b-card :title="titulo">
      <b-card-text>
        <b-form>
          <validation-observer ref="form">
            <b-row>
              <b-col cols="12" md="4" xl="3">
                <b-form-group
                  label="Rango de Fechas:"
                  label-for="rangoFechas"
                  label-cols-lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Rango de Fechas"
                    rules="required"
                    vid="rangoFechas"
                  >
                    <flat-pickr
                      id="rangoFechas"
                      v-model="rangoFechas"
                      class="form-control form-control-sm-small"
                      :config="flatPickrConfig"
                      @on-change="onChange"
                      @on-close="onClose"
                      placeholder="Seleecione un rango de fechas"
                    />
                    <small class="text-danger text-small">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" xl="3">
                <b-form-group
                  label="Usuario:"
                  label-for="usuario"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Usuario"
                    rules="required"
                  >
                    <v-select
                      id="usuario"
                      v-model="form.usuarioId"
                      label="nombre"
                      class="style-chooser select-size-sm"
                      placeholder="Buscar Usuario Por Nombre"
                      :filterable="false"
                      :clearable="false"
                      :options="usuarios"
                      :key="form.usuarioId"
                      :reduce="(opcion) => opcion.id"
                      @search="buscarUsuarios"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search">
                          Lo siento, no hay opciones de coincidencia
                        </span>
                        <span v-else>Digite para buscar</span>
                      </template>
                    </v-select>
                    <small class="text-danger text-small">
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
                xl="6"
                class="mt-1 mt-md-0 pt-personalizado"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="restablecer"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="RotateCwIcon" class="mr-50" />
                    <span class="align-middle">RESTABLECER</span>
                  </span>
                  <feather-icon
                    icon="RotateCwIcon"
                    class="d-md-none"
                    size="14"
                  />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="validar(false)"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span class="align-middle">CONSULTAR EN PANTALLA</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-1"
                  @click="validar(true)"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span class="align-middle">VISUALIZAR EN PDF</span>
                  </span>
                  <feather-icon icon="FileIcon" class="d-md-none" size="14" />
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card-text>

      <b-card-text>
        <component
          :is="componenteListado"
          ref="listado"
          :items="items"
          :loading="loading"
        ></component>
      </b-card-text>
    </b-card>

    <ModalMostrarPdf
      :titulo="`${titulo} del ${this.form.desde} al ${this.form.hasta}`"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :mostrarModal="mostarModal"
      @cerrarModal="cerrarModal"
    />
  </div>
</template>
    
<script>
import Ripple from "vue-ripple-directive";
import ReportesMixin from "../../mixins/ReportesMixin";
import ListadoMovimientoGeneral from "../views/ListadoMovimientoGeneral.vue";
import { formatearFecha } from "../../utils";
import vSelect from "vue-select";
import UsuarioServices from "../../../configuracion/usuario/services/index.js";

export default {
  name: "SedeHastaDesdeLayout",
  mixins: [ReportesMixin],
  props: {
    servicioGetReporte: {
      type: Function,
      required: true,
    },
    componenteListado: {
      type: String,
      required: true,
      validator: (value) => {
        return ["ListadoMovimientoGeneral"].includes(value);
      },
    },
  },
  components: {
    ListadoMovimientoGeneral,
    vSelect,
  },
  directives: {
    ripple: Ripple,
  },
  data() {
    return {
      form: {
        desde: formatearFecha(new Date()),
        hasta: formatearFecha(new Date()),
        usuarioId: null,
      },
      usuarios: [],
    };
  },
  methods: {
    restablecer() {
      this.rangoFechas = [
        formatearFecha(new Date()),
        formatearFecha(new Date()),
      ];
      this.form.usuarioId = null;
      this.items = [];
    },
    buscarUsuarios(search, loading) {
      if (search.length) {
        clearTimeout(this.retraso);
        this.retraso = setTimeout(async () => {
          loading(true);
          try {
            const response = await UsuarioServices.getUsuarioPorSede({
              sede_id: this.mixing.sede_id,
              q: search,
            });

            this.usuarios = response.data;
          } catch (error) {
            console.log(error);
          } finally {
            loading(false);
          }
        }, 350);
      } else {
        this.usuarios = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reportes.scss";
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.text-small {
  font-size: 11px;
}

@media (min-width: 1500px) {
  .text-small {
    font-size: 12px;
  }
}
</style>
    